.App {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  
  }
  @media screen and (max-width: 700px) {
    .App {
        margin: 4px 1rem;
    }
  }